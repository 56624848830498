import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { LicensePlateMap } from 'client/tracking/maps/appraisal/common/license-plate';

const map = {
  appraisal_tab_selected: TrackingEvent.actionStart(TrackingConstant.ACTION_TOOL_SELECT, TrackingConstant.TAB_SELECT),

  appraiser_retarget_continue_appraisal: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_CONTINUE_APPRAISAL
  ),

  appraiser_retarget_view_offer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_VIEW_APPRAISAL
  ),

  appraiser_retarget_renew_offer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SUBACTION_RENEW_OFFER
  ),
};

export const AppraisalTabsMap = {
  ...map,
  ...LicensePlateMap,
};
