import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const LicensePlateMap = {
  epo_submit_license_plate: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBMIT_LICENSE_PLATE
  ),
  appraisal_submit_license_plate: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_APPRAISE_VEHICLE,
    TrackingConstant.SUBMIT_LICENSE_PLATE
  ),
};
